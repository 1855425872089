@import 'src/styles/all';

.card {
  height: 120px;
  aspect-ratio: 8/5;
  color: #ffffff;
  border-radius: 0.5rem;

  &.sm {
    height: 100px;
  }

  &.selected {
    box-shadow: inset 0 0 0 3px $primary;
  }

  .detail {
    position: relative;
    // width: 100%;
    height: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;

    .primary {
      font-size: 10px;
    }
  }

  background: linear-gradient(25deg, #0f509e, #1399cd);

  .brand {
    height: 20px;
    margin-left: auto;
    filter: invert(100%);
  }

  small {
    font-size: 12px;
    color: $primary;
  }
}
