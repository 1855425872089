@import url('https://fonts.googleapis.com/css2?family=Cormorant:wght@400;600;700&family=Material+Icons+Outlined&display=swap');
@import 'src/styles/classes';
@import '~bulma/bulma';

html {
  font-size: 16px;
  overflow: auto;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #1b262f;
  font-size: 14px;
  line-height: 1.2;
}

a,
.is-link {
  color: $anchor;
  cursor: pointer;

  &:hover {
    color: $anchor;
  }

  &.is-link-neutral {
    color: $primary;
  }
}

p {
  margin-block: 1rem;
}

div {
  box-sizing: border-box;
}

ol {
  margin-left: 1.5rem;
}

img {
  display: block;
  object-fit: cover;
}

video {
  background: black;
}

.serif {
  font-family: 'cormorant', serif;
  line-height: 1;
}

.carousel-root {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;

  .carousel {
    height: 100%;

    .slider-wrapper {
      height: 100%;

      .slider {
        height: 100%;
      }
    }
  }
}

.button {
  background: $primary;
  color: #ffffff;
  border: 1px solid $primary;
  border-radius: 0 !important;
  padding: 0 1.5rem;

  &:hover {
    border-color: $primary;
    background: darken($primary, 10%);
    color: white;
  }

  &:focus {
    color: #ffffff;
    box-shadow: none !important;
  }

  &:active {
    color: #ffffff;
  }

  &:disabled {
    border: 1px solid $primary;
    background: $primary;
    opacity: 0.6;
    cursor: default;
  }

  &.is-clear {
    background: none;
    color: $text;
    border: none;
  }

  &.is-outlined {
    background: none;
    color: $primary;
  }

  &.is-small {
    padding: 0.125rem 0.5rem;
    font-size: 12px;
    height: unset;
  }
}

.modal {
  z-index: 10000;

  &.image-viewer {
    .modal-content {
      width: 100vw !important;
      height: 100vh !important;
      padding: 0 !important;
    }
  }
  .modal-content {
    background: #fff;
    padding: 2rem;
  }
}

.list {
  list-style-type: none;
  margin: 0;

  &.inline {
    display: flex;
    align-items: center;

    li:not(:last-child) {
      margin-right: 1rem;
    }
  }

  &.link {
    li {
      cursor: pointer;
    }
  }
}

.classic-list {
  list-style-type: disc;
  margin-left: 1rem;

  &.check {
    list-style-type: '✓';
    li {
      padding-left: 8px;
    }
  }
}

.container {
  @include touch {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

.has-text-muted {
  color: $primary-muted;
}

.field {
  .label {
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 0.25rem;
  }

  .control {
    .input {
      border-color: $border;
      border-radius: 0;
      box-shadow: none;

      &.is-danger {
        border-color: $red;
      }

      &:focus {
        border-color: $primary;
        background-color: rgba($primary, 0.2);
      }
    }
  }
}

.table {
  background: #f8faff;
  width: 100%;
}
