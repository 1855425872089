@import 'src/styles/all';

.banner {
  width: 100%;
  height: 420px;
  margin-top: -19px;
  background: $background-tint-4;

  @include touch {
    margin-top: -128px;
  }

  img {
    width: 100%;
    height: 100%;
    object-position: top;
  }
}

.ocean {
  // margin-top: 180px;

  .art {
    width: 120px;
    height: 120px;
    margin: auto;
    box-shadow: $highlight 0 0 1rem 0;
    background: $background-tint-4;
  }

  .avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }

  .description {
    max-width: 720px;
    margin: auto;
  }

  .thumbnail {
    width: 100%;
    height: 100%;
  }
}

.mosaic {
  cursor: pointer;
}

.collection-skeleton {
  margin: 0.5rem;
}

.tune {
  flex-wrap: wrap;
  .seller-profile {
    display: flex;
    align-items: center;
    margin-right: 1rem;
    &-img {
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 1.5rem;
      object-fit: cover;
      margin-right: 0.5rem;
    }
  }
  @include mobile {
    .pricing,
    .views {
      width: 50%;
      margin-bottom: 0.5rem;
    }

    .purchase {
      width: 100%;
    }
  }
}
