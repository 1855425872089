@import 'src/styles/all';

.header {
  padding: 2rem 0;
  background-color: $primary-tint-4;
  margin-top: -20px;

  .accord {
    flex-wrap: wrap;

    .video {
      margin-left: 1rem;

      .player {
        width: 100%;
        height: 100%;
        display: block;
      }
    }

    .video-intro-container {
      margin: auto;
      padding: 1rem;
      background: white;
      box-shadow: inset 0 0 0 1px #c8cdd4;
      .intro {
        // max-width: 360px;
        width: fit-content !important;
        height: fit-content !important;
        margin: auto;
      }
    }

    @include touch {
      .name,
      .video {
        flex: unset;
        width: 100%;
      }

      .video {
        margin-left: 0;
        margin-top: 1rem;
      }
    }
  }

  .avatar {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    margin: auto;
  }

  .description {
    max-width: 720px;
    margin: auto;
  }
}

.collections {
  justify-content: center;
  flex-wrap: wrap;

  .card {
    flex-shrink: 0;
    margin: 0 0.5rem 1rem;
    transition: 250ms ease;

    &:hover {
      box-shadow: rgba($highlight, 0.6) 0 0 0.5rem 0;
      transform: translateY(-1px);
    }
  }
}

.player {
  max-height: 320px;
  aspect-ratio: 16/9;
}

.collection-skeleton {
  margin: 0.5rem;
}

.coming-soon {
  height: 400px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
