@import 'src/styles/all';

.profile-avatar {
  .profile-avatar-label {
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 0.25rem;
  }

  .profile-picture {
    text-align: center;

    .avatar {
      width: 160px;
      height: 160px;
      margin: auto;
    }
  }
}
