.stretch {
  height: calc(100vh - 240px);

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    .login {
      width: 400px;
    }
  }
}
