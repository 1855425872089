@import 'src/styles/all';

.callout {
  border: 1px solid $primary;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  min-height: 80px;

  .highlight {
    background: $primary;
    color: #ffffff;
    padding: 1rem;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .more {
    flex: 1;
    text-align: right;
    white-space: nowrap;
  }
}

.collection-skeleton {
  margin: 0 0.5rem;
}
