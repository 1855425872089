.sell-modal {
  padding: 1rem;
  &-item {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    &-label {
      width: 90px;
    }
    &-content {
      display: flex;
      align-items: center;
      span:first-child {
        margin-right: 0.5rem;
      }
      span:last-child {
        margin-left: 0.5rem;
      }
    }
  }
}
