@import 'src/styles/all';

.header {
  padding: 4rem 0;
  background-color: $primary-tint-4;
  margin-top: -20px;

  .avatar {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    margin: auto;
  }

  .description {
    max-width: 720px;
    margin: auto;
  }
}

.collections {
  justify-content: center;
  flex-wrap: wrap;

  .card {
    flex-shrink: 0;
    margin: 0 0.5rem 1rem;
  }
}

.collection-skeleton {
  margin: 0.5rem;
}
