@import 'src/styles/all';

.snackbar {
  z-index: 20000;
  position: fixed !important;
  right: 16px;
  min-width: 320px;
  border-radius: 0 !important;
  top: 120px;
  color: #ffffff;

  &.is-primary {
    background: rgba($primary, 0.8);
  }

  &.is-success {
    background: rgba($success, 0.8);
  }

  &.is-danger {
    background: rgba($danger, 0.8);
  }
}
