$background-tint-1: #f9fafb;
$background-tint-2: #edeff4;
$background-tint-3: #e1e4ed;
$background-tint-4: #d5d9e5;

$gray: #dcdcdc;
$text: #1b262f;
$primary: #304555;
$highlight: #5b919e;
$primary-muted: rgba($primary, 0.6);
$border: #c8cdd4;
$anchor: #4c99d4;
$body-background-color: $background-tint-2;
$fullhd: 1344px;

$primary-tint-4: #d7e1e8;
$primary-tint-8: #a4bbcc;
