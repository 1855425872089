.purchase-information {
  text-align: initial;

  .thumbnail {
    height: 120px;
    width: 120px;
    object-fit: cover;
  }
}

.collaborator-avatar {
  width: 24px;
  height: 24px;
}
