@import 'src/styles/all';

.banner {
  display: flex;
  flex-wrap: wrap;
  background: #ffffff;
  height: 500px;

  @include mobile {
    height: unset;
  }

  .stub {
    width: 40%;
    padding: 2rem;
  }

  .preview {
    width: 60%;
    height: 100%;
    box-shadow: rgba($highlight, 0.6) 0 0 1rem 0;

    img {
      width: 100%;
      height: 100%;
    }
  }

  @include touch {
    flex-direction: column-reverse;

    .preview {
      width: 100%;
    }

    .stub {
      width: 100%;
      padding: 1rem;
    }
  }
}
