@import 'src/styles/classes';

.skeleton {
  animation: pulse 3s infinite;

  &.circle {
    border-radius: 50%;
  }
}

@keyframes pulse {
  0% {
    background: $background-tint-4;
  }

  40% {
    background: $background-tint-4;
  }

  50% {
    background: darken($background-tint-4, 20%);
  }

  60% {
    background: $background-tint-4;
  }

  100% {
    background: $background-tint-4;
  }
}
