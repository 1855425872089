.credit-brand {
  position: absolute;
  height: 20px;
  right: 12px;
  top: 10px;
}

button {
  display: none;
}
