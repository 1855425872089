@import 'src/styles/all';

.header {
  padding: 4rem 0;
  background-color: $primary-tint-4;
  margin-top: -20px;

  .avatar {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    margin: auto;
  }

  .description {
    max-width: 720px;
    margin: auto;
  }
}

.marketplace {
  width: 100%;
  height: calc(100vh - 240px);
  display: flex;
  align-items: center;
  justify-content: center;
}
