@import 'styles/all';

.crypto-table {
  width: 100%;
}

.public-address {
  max-width: 120px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.public-address {
  width: 120px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.at-logo {
  height: 1.5rem;
}

.connect-wallet {
  .metamask {
    text-align: center;
    width: 160px;
    border: 1px solid #c8cdd4;
    padding: 1rem;
    margin: auto;
    cursor: pointer;

    &:hover {
      background: rgba($primary, 0.2);
    }

    img {
      margin: auto;
      text-align: center;
    }
  }
}
