@import 'src/styles/all';

.header {
  padding: 4rem 0;
  background-color: $primary-tint-4;
  margin-top: -20px;
}

.section {
  padding: 5rem 0;
  display: flex;
  justify-content: center;

  &.founders {
    .about {
      width: 18rem;
      margin: 0 1rem;

      @include touch {
        width: 100%;
        margin-bottom: 1rem;
      }

      img {
        width: 8rem;
        height: 8rem;
        margin: auto;
      }
    }
  }
}

.air {
  line-height: 1.5;
}

.ocean {
  max-width: 960px !important;
}

.justify {
  text-align: justify;
  text-justify: inter-word;
}
