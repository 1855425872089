@import 'src/styles/all';

.pad {
  padding-top: 40px;

  @include desktop {
    height: calc(100vh - 280px);
  }

  @include touch {
    &.mobile-view-1 {
      .settings {
        display: block;
      }

      .pandora {
        display: none;
      }
    }

    &.mobile-view-2 {
      .settings {
        display: none;
      }

      .pandora {
        display: block;
      }
    }
  }
}

.settings {
  width: 240px;

  @include touch {
    width: 100%;
  }

  .header {
    margin-bottom: 1rem;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
  }

  .settings-list {
    li {
      padding: 2px;

      &:not(:last-child) {
        border-bottom: 1px solid $border;
      }

      .item {
        padding: 1rem;
        color: rgba($text, 0.6);
        position: relative;
        cursor: pointer;

        &:hover {
          background-color: $background-tint-3;
          color: $text;
        }

        &.active {
          // background-color: $background-tint-4;
          color: $text;
          font-weight: 600;
        }

        &.danger {
          font-weight: 600;
          color: $red;
        }
      }
    }
  }
}

.pandora {
  flex: 1;
  width: 100%;
  height: 100%;
  margin-left: 2rem;
  padding: 0 2rem;
  overflow-y: auto;
  position: relative;

  .back {
    display: none;
  }

  @include touch {
    margin-left: unset;
    overflow-y: unset;
    padding: 0;

    .back {
      display: block;
    }
  }
}
