.message {
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 2rem;
}

.actions {
  width: 100%;
  display: flex;
  justify-content: center;
}
