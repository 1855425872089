.no-collections {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 6rem;
  border: 1px solid #c8cdd4;
  border-radius: 0.5rem;
  padding: 0 1rem;
  text-align: center;
}

.collection {
  display: flex;
  padding: 1rem;
  border: 1px solid #c8cdd4;
  margin-top: 1rem;
  background: #f8faff;

  &-img {
    width: 10rem;
    height: 15rem;
    margin-right: 1rem;
    object-fit: cover;
  }

  &-content {
    display: flex;
    flex-direction: column;
    p {
      margin: 0;
      margin-bottom: 0.3rem;
      word-break: break-all;
    }
    &-bottom {
      margin-top: auto;
    }
  }
}
