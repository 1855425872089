@import 'src/styles/all';

.figure {
  width: fit-content;
  margin: auto;
  background-repeat: no-repeat;
  cursor: grab;
  &.drag {
    cursor: grabbing;
  }

  &.zoom-in {
    cursor: zoom-in;
  }
}

.figure-img {
  display: block;
  pointer-events: none;
  object-fit: contain;
  &.hover {
    height: 100vh;
    opacity: 0;
  }
}
.image-zoom {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: content;
  text-align: center;
  height: auto;
  width: auto;
  width: fit-content;
  &.full {
    overflow: hidden;
    &.landscape {
      img {
        height: fit-content;
        // height: 100%;
        // width: auto;
      }
      // width: 100%;
    }
    &.portrait {
      overflow: initial;
      img {
        width: fit-content;
        // height: auto;
        // width: 100%;
      }
    }
  }
  // overflow: hidden;
  max-height: 100%;
  max-width: 100%;

  img {
    overflow: hidden;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: contain;
  }
}

.controls {
  bottom: 3rem;
  margin: auto;
  position: absolute;
  display: table;
  left: calc(50% - 40px);

  .zoom-button {
    background-color: #00000099;
    padding: 0.5rem;
    border: 0;
    opacity: 0.8;
    display: inline-block;
    cursor: pointer;

    &.disabled {
      opacity: 0.4;
    }
  }
}

.thumbnail {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  border: 5px solid #ffffff99;
  right: 2rem;
  position: absolute;
  bottom: 1rem;
  &.portrait {
    height: auto;
    width: 15vw;
  }
  &.landscape {
    height: auto;
    width: 15vw;
  }
  .boundary {
    transition: all 0.1;
    z-index: 10000;
    position: absolute;
    background-color: white;
    opacity: 0.4;
  }
  img {
    height: 100%;
  }
}
