.history-table {
  width: 100%;
}

.profile-img {
  min-width: 4rem;
  height: 4rem;
  object-fit: cover;
}

.description {
  margin: 0;
  padding: 0;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
