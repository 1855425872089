@mixin mobile {
  @media (max-width: 72rem) {
    @content;
  }
}

.app {
  width: 100%;
}

.sign-in {
  width: 30rem !important;
}

.section-1 {
  background: #bdceda;
  height: 100vh;

  @supports (-webkit-touch-callout: none) {
    height: -webkit-fill-available;
  }

  .logo {
    height: 20rem;

    @include mobile {
      height: 12rem;
    }
  }

  .authentic {
    font-weight: 700;
    letter-spacing: 0.25rem;
    margin-top: 2rem;
  }
}
