@import 'variables';
@import '~bulma/sass/utilities/all';

@mixin touch {
  @media (max-width: $tablet) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $desktop) {
    @content;
  }
}

@mixin screen-2 {
  @media (max-width: $desktop) {
    @content;
  }
}

@mixin screen-3 {
  @media (max-width: $widescreen) {
    @content;
  }
}

.position-relative {
  position: relative;
}

.height-full {
  height: 100%;
}

.width-thirds {
  width: calc(100% / 3);
}

.width-full {
  width: 100%;
}

.avatar {
  border-radius: 50%;
  display: block;
}

.flexbox {
  display: flex;
  align-items: center;

  &.wrap {
    flex-wrap: wrap;
  }

  &.flex-column {
    flex-direction: column;
  }

  &.align-start {
    align-items: flex-start;
  }

  &.justify-center {
    justify-content: center;
  }

  &.justify-around {
    justify-content: space-around;
  }

  &.justify-end {
    justify-content: flex-end;
  }

  &.justify-between {
    justify-content: space-between;
  }

  .flex {
    flex: 1;
  }
}

.trance-wrapper {
  margin: 0 -0.5rem;

  .trance {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;

    &.small {
      .trance-card {
        width: calc(100% / 6 - 1rem);

        @include touch {
          width: calc(100% / 2 - 1rem);
        }
      }
    }

    .trance-card {
      border: 1px solid $border;
      padding: 1rem;
      margin: 0.5rem;
      transition: 250ms ease;
      cursor: pointer;
      background: #ffffff;
      width: calc(100% / 4 - 1rem);

      @include screen-3 {
        width: calc(100% / 3 - 1rem);
      }

      @include touch {
        width: calc(100% / 2 - 1rem);
      }

      &:hover {
        border-color: $primary-muted;
        box-shadow: rgba($primary-muted, 0.6) 0 4px 8px 0;
        transform: translateY(-4px);

        &.clear {
          padding: 0;
          border: none;
        }
      }

      &.clear {
        padding: 0;
        border: none;
      }

      .top {
        width: 100%;
        aspect-ratio: 1/1;

        .thumbnail {
          width: 100%;
          height: 100%;
        }
      }

      .bottom {
        display: flex;
        align-items: center;
        margin-top: 0.5rem;
      }
    }
  }
}

.icon {
  width: 24px;
  height: 24px;

  &.is-small {
    width: 16px;
    height: 16px;
  }
}

.hidden {
  visibility: hidden;
}
