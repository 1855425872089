@import 'src/styles/all';

.header {
  padding: 4rem 0;
  background-color: $primary-tint-4;
  margin-top: -20px;

  .avatar {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    margin: auto;
  }

  .description {
    max-width: 720px;
    margin: auto;
  }
}

.collaborators {
  .preview {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(#000000, 0.8);
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;

    .foreground {
      text-align: center;
      z-index: 100;
      color: #ffffff;

      .thumbnail {
        width: 120px !important;
        height: 120px !important;
        border-radius: 50%;
      }
    }

    .background {
      position: absolute;
      transform: scale(1.1);
      filter: blur(4px);
      opacity: 0.6;
      height: 100%;
    }
  }
}

.collection-skeleton {
  margin: 0.5rem;
}
