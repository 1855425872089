@import 'src/styles/all';

.big {
  .group {
    text-align: center;
    width: 64px;

    .time {
      font-variant-numeric: tabular-nums;
      font-size: 32px;
      font-weight: 700;
    }

    .sub {
      font-size: 12px;
      color: $primary-muted;
    }
  }

  .colon {
    font-size: 40px;
    font-weight: 700;
    margin: 0 4px;
  }
}

.small {
  .time {
    font-variant-numeric: tabular-nums;
    font-size: 24px;
    font-weight: 700;
  }

  .sub {
    font-size: 14px;
  }

  .colon {
    font-size: 20px;
    font-weight: 700;
    margin: 0 2px;
  }
}
