.label {
  font-size: 12px;
  display: block;
  cursor: pointer;
}

.checkbox {
  margin-right: 0.25rem;
  cursor: pointer;
  line-height: 0;

  i {
    font-size: 16px;
  }
}

.hidden {
  display: none;
}
