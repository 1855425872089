.card-grid {
  display: flex;
  flex-wrap: wrap;

  .card-cell {
    margin-bottom: 48px;
    margin-right: 24px;
    position: relative;
    cursor: pointer;
  }
}

.options {
  position: absolute;
  bottom: -32px;
}
