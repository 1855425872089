@import 'src/styles/classes';

.navigation {
  color: #000000;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  background-color: rgba(#ffffff, 0.6);
  backdrop-filter: blur(0.5rem);

  .title {
    margin-right: auto;
    cursor: pointer;

    .logo {
      height: 3rem;
    }

    .type {
      white-space: nowrap;
      margin-left: 0.5rem;
    }
  }

  @include touch {
    .title {
      width: 100%;
    }

    .map {
      margin-top: 0.5rem;
      width: 100%;
    }
  }

  .mobile-mirage {
    padding: 0 1rem;

    ul {
      margin-top: 1rem;

      li {
        padding: 0.5rem 0;
        cursor: pointer;
      }
    }
  }
}

.footer {
  background: $primary;
  margin-top: 40px;
  color: #ffffff;
  padding: 20px 0;

  .logo {
    height: 5rem;
  }

  .footer-menu {
    display: flex;
    justify-content: flex-end;
    margin-right: -0.5rem;

    @include touch {
      width: 100%;
      justify-content: center;
      margin-top: 1rem;
    }

    div {
      margin: 0 0.5rem;

      a {
        color: #ffffff;
      }
    }
  }
}

.routes {
  min-height: calc(100vh - 280px);
  margin-top: 100px;
  line-height: 1.5;

  @include touch {
    margin-top: 128px;
  }
}

.map {
  display: flex;
  align-items: center;

  @include touch {
    width: 100%;
  }

  .mobile-menu {
    padding: 0;
    width: 36px;
    height: 32px;

    @include desktop {
      display: none;
    }
  }

  .map-list {
    margin-left: auto;
  }

  .clearance {
    font-size: 14px;
  }

  .map-menu {
    li:last-child {
      padding-right: 1rem;
    }

    @include touch {
      display: none;

      li {
        margin-right: 0.5rem !important;

        &:last-child {
          margin-right: 0 !important;
        }
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .avatar {
    width: 40px;
    height: 40px;
  }

  .account {
    flex-shrink: 0;

    @include touch {
      margin-left: auto;
    }
  }
}

.copyright {
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
